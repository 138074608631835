export const desktopMenus = [
  {
    key: "home",
    title: "Home",
    href: "/",
    type: "general",
  },

  {
    key: "products",
    title: "Products",
    href: "/view-all-products",
    type: "parent",
    children: [
      {
        key: "closets",
        title: "Closets",
        href: "/products/closets",
        type: "child",
      },
      {
        key: "kitchens",
        title: "Kitchen & Bath",
        href: "/products/kitchen-bath",
        type: "child",
      },
      {
        key: "homeOffices",
        title: "Home Offices",
        href: "/products/home-offices",
        type: "child",
      },
      {
        key: "mediaCenters",
        title: "Media Centers",
        href: "/products/media-centers",
        type: "child",
      },
      {
        key: "libraries",
        title: "Libraries",
        href: "/products/libraries",
        type: "child",
      },
      {
        key: "garageCabinates",
        title: "Garage Cabinets",
        href: "/products/garage-cabinets",
        type: "child",
      },
      {
        key: "Pantry",
        title: "Pantry",
        href: "/products/pantry",
        type: "child",
      },
      {
        key: "laundryRooms",
        title: "Laundry & Mudroom",
        href: "/products/laundry-rooms",
        type: "child",
      },
      {
        key: "wallBeds",
        title: "Wall Beds",
        href: "/products/wall-beds",
        type: "child",
      },
      {
        key: "materialsAndFinishes",
        title: "Materials & Finishes",
        href: "/materials-and-finishes",
        type: "child",
      },
      {
        key: "cabinet_doors",
        title: "Cabinet Doors",
        href: "/products/cabinets",
        type: "child",
      },
      {
        key: "360-page",
        title: "Virtual 360 Tours",
        href: "/360-page",
        type: "child",
      },
      {
        key: "view_all_products",
        title: "View All Products",
        href: "/view-all-products",
        type: "child",
      }

    ],
  },
  {
    key: "location",
    title: "Locations",
    href: "/locations",
    type: "general",
  },
  {
    key: "about",
    title: "About",
    href: "/why-choose-classy-closets",
    type: "parent",
    children: [
      {
        key: "why_choose_us",
        title: "Why Choose Classy Closets",
        href: "/why-choose-classy-closets",
        type: "child",
      },
      {
        key: "ourStory",
        title: "Our Story",
        href: "/our-story",
        type: "child",
      },
      {
        key: "faq",
        title: "FAQ",
        href: "/faq",
        type: "child",
      },
      {
        key: "franchise_opportunities",
        title: "Franchise Opportunities",
        href: "/franchise-opportunities",
        type: "child",
      },
      {
        key: "privacy",
        title: "Privacy Policy",
        href: "/privacy-policy",
        type: "child",
      },
      /*  {
          key: "360page",
          title: "Virtual 360 Tours",
          href: "/360-page",
          type: "child",
        },*/
      {
        key: "customer-service",
        title: "Customer Service",
        href: "/customer-service",
        type: "child",
      },
    ],
  },
  {
    key: "blog",
    title: "Blog",
    href: "/blog",
    type: "general",
  },
  {
    key: "contact",
    title: "Contact Us",
    href: "/get-in-touch",
    type: "general",
  },
  {
    key: "quick_contact",
    title: "Start Your Project",
    href: "#",
    type: "contact_form",
    isHighlighted: true
  },
];

export const desktopMenusOriginal = [
  {
    key: "home",
    title: "Home",
    href: "/",
    type: "general",
  },
  {
    key: "products",
    title: "Products",
    href: "/view-all-products",
    type: "parent",
    children: [
      {
        key: "closets",
        title: "Closets",
        href: "/closets",
        type: "category",
      },
      {
        key: "homeOffices",
        title: "Home Offices",
        href: "/home-offices",
        type: "child",
      },
      {
        key: "mediaCenters",
        title: "Media Centers",
        href: "/media-centers",
        type: "child",
      },
      {
        key: "libraries",
        title: "Libraries",
        href: "/libraries",
        type: "child",
      },
      {
        key: "garageCabinates",
        title: "Garage Cabinets",
        href: "/garage-cabinets",
        type: "child",
      },
      {
        key: "Pantry",
        title: "Pantry",
        href: "/pantry",
        type: "category",
      },
      {
        key: "laundryRooms",
        title: "Laundry Rooms",
        href: "/laundry-rooms",
        type: "child",
      },
      {
        key: "murphyBeds",
        title: "Murphy Beds",
        href: "/murphy-beds",
        type: "child",
      },
      {
        key: "kitchens",
        title: "Kitchen & Bath",
        href: "/kitchen-bath",
        type: "child",
      },
    ],
  },
  {
    key: "location",
    title: "Locations",
    href: "/locations",
    type: "general",
  },
  {
    key: "about",
    title: "About",
    href: "/why-choose-classy-closets",
    type: "parent",
    children: [
      {
        key: "contact",
        title: "Contact Us",
        href: "/get-in-touch",
        type: "child",
      },
      {
        key: "faq",
        title: "FAQ",
        href: "/frequently-asked-questions",
        type: "child",
      },
      {
        key: "privacy",
        title: "Privacy Policy",
        href: "/privacy-policy",
        type: "child",
      },
      {
        key: "materials and finishes",
        title: "Materials & Finishes",
        href: "/materials-and-finishes",
        type: "child",
      },
    ],
  },
  {
    key: "blog",
    title: "Blog",
    href: "",
    type: "parent",
    children: [
      {
        key: "blog1",
        title: "Blog Section",
        href: "/",
        type: "category",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
      {
        key: "blog1",
        title: "Blog1",
        href: "/",
        type: "child",
      },
    ],
  },
];

export const mobileFooterMenus = [
  {
    key: "why_choose_us",
    title: "Why Choose Us",
    href: "/why-choose-classy-closets",
    type: "general",
  },
  {
    key: "locations",
    title: "Locations",
    href: "/locations",
    type: "general",
  },
  {
    key: "franchise_opportunities",
    title: "Franchise Opportunities",
    href: "/franchise-opportunities",
    type: "general",
  },
  {
    key: "careers",
    title: "Careers",
    href: "/careers",
    type: "general",
  },
  {
    key: "accessibility",
    title: "Accessibility Statement",
    href: "/accessibility-statement",
    type: "general",
  },
];
